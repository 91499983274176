import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SEO from "../components/SEO.jsx";
import { CurrentState } from "../context/currentState.js";
import { USER_STATES } from "../context/currentState.js";
import { AlbumsMenu } from "../components/AlbumsMenu.jsx";
import Credits from "./Credits.jsx";
import Lyrics from "../components/Lyrics.jsx";
import Menu from "./Menu.jsx";
import { LandingPage } from "../components/LandingPage.jsx";
import { Loading } from "../pages/Loading.jsx";
import { CacheApi } from "../context/cacheApi.js";
import "../styles/homeMenus.css";

export default function Home() {
  const {
    currentSong,
    setCurrentSongById,
    setCurrentSongByAlbum,
    userState,
    handleUserStateChange,
    setPrevPage,
  } = React.useContext(CurrentState);

  const { songsByAlbum } = React.useContext(CacheApi);

  const { songId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [menuAlbumId, setMenuAlbumId] = useState(1);
  const [muted, setMuted] = useState(true);
  const [showingDropdownOptions, setShowingDropdownOptions] = useState(false);
  const [viewingMenu, setViewingMenu] = useState("none");

  function handleAlbumCoverClick(albumId) {
    setMenuAlbumId(albumId);
    handleUserStateChange(USER_STATES.ALBUMS_MENU);
  }

  function handleMute() {
    setMuted(true);
  }

  function handleUnmute() {
    setMuted(false);
  }

  function handleAlbumChange(currentAlbumId) {
    let newAlbumId = null;
    if (currentAlbumId === 1) {
      newAlbumId = 2;
    }

    if (currentAlbumId === 2) {
      newAlbumId = 1;
    }
    setCurrentSongByAlbum(newAlbumId);
  }

  function handleSongChange(newSongId) {
    setCurrentSongById(newSongId);
    setShowingDropdownOptions(false);
  }

  function handleDropdownClick() {
    setShowingDropdownOptions(!showingDropdownOptions);
  }

  function handleCreditsClick() {
    handleUserStateChange(USER_STATES.VIEWING_CREDITS);
  }

  function handleLyrisClick() {
    handleUserStateChange(USER_STATES.VIEWING_LYRICS);
  }

  function handleMenuClick() {
    setViewingMenu("block");
    setShowingDropdownOptions(false);
  }

  useEffect(() => {
    setPrevPage("/");

    if (userState === USER_STATES.LOADING_PAGE) {
      if (songId) {
        setCurrentSongById(songId);
      } else {
        setCurrentSongByAlbum(1);
      }
    }

    let timeoutId;

    const handleMouseMove = () => {
      clearTimeout(timeoutId);
      if (userState === USER_STATES.VIEWING_SONG) {
        handleUserStateChange(USER_STATES.SONG_MENU);
      } else {
        handleUserStateChange(USER_STATES.SONG_MENU);
      }
    };

    const handleMouseStop = () => {
      timeoutId = setTimeout(() => {
        handleUserStateChange(USER_STATES.VIEWING_SONG);
      }, 2000);
    };

    if (
      userState === USER_STATES.SONG_MENU ||
      userState === USER_STATES.VIEWING_SONG
    ) {
      timeoutId = setTimeout(() => {
        setShowingDropdownOptions(false);
        handleUserStateChange(USER_STATES.VIEWING_SONG);
      }, 2000);

      let mouseStopTimeout;
      const debouncedMouseStop = () => {
        clearTimeout(mouseStopTimeout);
        mouseStopTimeout = setTimeout(handleMouseStop, 100);
      };

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mousemove", debouncedMouseStop);

      return () => {
        clearTimeout(timeoutId);
        clearTimeout(mouseStopTimeout);
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mousemove", debouncedMouseStop);
      };
    }
  }, [userState]);

  useEffect(() => {
    setViewingMenu("none");
  }, [location]);

  return (
    <>
      <SEO
        title="MASSAIÁ"
        content="Espaços do som, da voz e do olhar. Massaiá é uma palavra inventada que intitula a canção e o álbum duplo da cantautora Helena Caspurro, associado ao projeto Espaços do Som, desenvolvido com apoio do INET-md e DeCA da Universidade de Aveiro, com a participação de diversos músicos e artistas. Massaiá ou massaia é uma palavra inventada que intitula a canção e o álbum duplo da cantautora Helena Caspurro. Massaia."
        href={window.location.href}
      />
      {userState === USER_STATES.LOADING_PAGE ? (
        <Loading />
      ) : (
        <>
          <div
            className="videoPlayer"
            onClick={() => handleUserStateChange(USER_STATES.SONG_MENU)}
          >
            <div className="overlay"></div>
            {currentSong.video != null ? (
              <video
                className="menu-albums-song"
                src={`${process.env.REACT_APP_UPLOAD_FOLDER}${currentSong.video}`}
                autoPlay
                loop
                muted={muted}
                playsinline="true"
                webkit-playsinline="true"
                controls={false}
                style={{ objectFit: "cover", width: "100%", height: "100%" }}
              />
            ) : (
              <>
                <img
                  loading="eager"
                  className="menu-albums-song"
                  src={`${process.env.REACT_APP_UPLOAD_FOLDER}${currentSong.image}`}
                  alt="song cover"
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                  title="song cover"
                />

                <audio
                  src={`${process.env.REACT_APP_UPLOAD_FOLDER}${currentSong.audio}`}
                  autoPlay
                  loop
                  muted={muted}
                />
              </>
            )}
            {userState === USER_STATES.LANDING_PAGE && <LandingPage />}
            {userState === USER_STATES.SONG_MENU && (
              <div>
                <div className="massaia">
                  <p>MASSAIÁ</p>
                </div>
                <div className="menu" onClick={() => handleMenuClick()}>
                  <img
                    loading="eager"
                    src="../assets/icons/menu-white.svg"
                    alt="menu"
                    title="menu"
                  />
                </div>
                <div className="album-cover">
                  <p>vol. I</p>
                  <img
                    loading="eager"
                    src={`${process.env.REACT_APP_UPLOAD_FOLDER}/${songsByAlbum[1][0]?.album_cover}`}
                    alt="album cover"
                    title="album cover"
                    onClick={() => handleAlbumCoverClick(1)}
                  />
                  <img
                    loading="eager"
                    src={`${process.env.REACT_APP_UPLOAD_FOLDER}/${songsByAlbum[2][0]?.album_cover}`}
                    alt="album cover"
                    title="album cover"
                    onClick={() => handleAlbumCoverClick(2)}
                  />
                  <p>vol. II</p>
                </div>
                <div className="credits">
                  {currentSong.lyrics ? (
                    <img
                      loading="eager"
                      onClick={() => handleLyrisClick()}
                      src="../assets/icons/lyrics.svg"
                      alt="lyrics"
                      title="lyrics"
                    />
                  ) : (
                    <></>
                  )}

                  {muted ? (
                    <img
                      loading="eager"
                      className="sound"
                      src="../assets/icons/sound-off.svg"
                      alt="sound off"
                      title="sound off"
                      onClick={() => handleUnmute()}
                    />
                  ) : (
                    <img
                      loading="eager"
                      className="sound"
                      src="../assets/icons/sound-on.svg"
                      alt="sound on"
                      title="sound on"
                      onClick={() => handleMute()}
                    />
                  )}

                  <p onClick={() => handleCreditsClick()}>créditos</p>
                </div>
                <div className="song-info">
                  <div>
                    {currentSong.album_id === 1 ? (
                      <p
                        onClick={() => handleAlbumChange(currentSong.album_id)}
                        className="vol"
                      >
                        vol. I
                      </p>
                    ) : (
                      <p
                        onClick={() => handleAlbumChange(currentSong.album_id)}
                        className="vol"
                      >
                        vol. II
                      </p>
                    )}
                    <DropdownButton
                      key={"up"}
                      id={`dropdown-button-drop-up-centered`}
                      drop={"up"}
                      variant="secondary"
                      title={currentSong.name}
                      show={showingDropdownOptions}
                      onClick={() => handleDropdownClick()}
                      className="custom-dropdown"
                    >
                      {showingDropdownOptions &&
                        (songsByAlbum[currentSong.album_id]?.length > 0 ? (
                          songsByAlbum[currentSong.album_id].map((song) => (
                            <Dropdown.Item
                              key={song.id}
                              onClick={() => handleSongChange(song.id)}
                              className="custom-dropdown-item"
                            >
                              {song.name}
                            </Dropdown.Item>
                          ))
                        ) : (
                          <Dropdown.Item disabled>
                            No songs available
                          </Dropdown.Item>
                        ))}
                    </DropdownButton>
                  </div>
                </div>
              </div>
            )}
            {userState === USER_STATES.ALBUMS_MENU && (
              <AlbumsMenu songsInfo={songsByAlbum[menuAlbumId]} />
            )}
            {userState === USER_STATES.VIEWING_CREDITS && (
              <Credits songId={currentSong.id} />
            )}
            {userState === USER_STATES.VIEWING_LYRICS && (
              <Lyrics songId={currentSong.id} />
            )}
          </div>

          <div id="menu-component" style={{ display: viewingMenu }}>
            <Menu />
          </div>
        </>
      )}
    </>
  );
}
