import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import "../styles/musicControllers.css";
import { CacheApi } from "../context/cacheApi.js";
import { CurrentState } from "../context/currentState.js";

export default function MusicControllers() {
  const { currentSong, setCurrentSongById, setCurrentSongByAlbum } =
    React.useContext(CurrentState);

  const { songsById, songsByAlbum } = React.useContext(CacheApi);

  const [muted, setMuted] = useState(true);
  const [play, setPlay] = useState(false);
  const audio = document.getElementById("audio_tag");

  const [showingDropdownOptions, setShowingDropdownOptions] = useState(false);

  function handlePrevSong() {
    const keys = Object.keys(songsById);
    let newSongId = keys.at(keys.indexOf(currentSong.id.toString()) - 1);
    setCurrentSongById(newSongId);
  }

  function handleNextSong() {
    const keys = Object.keys(songsById);
    let newSongId = keys.at(keys.indexOf(currentSong.id.toString()) + 1);

    if (newSongId === undefined) {
      newSongId = keys[0];
    }

    setCurrentSongById(newSongId);
  }

  function handleMute() {
    if (!play && muted) {
      return;
    }
    setMuted(!muted);
  }

  function handlePlaySong() {
    play ? setPlay(false) : setPlay(true);
    play ? audio.pause() : audio.play();
    play ? setMuted(true) : setMuted(false);
  }

  function handleAlbumChange(currentAlbumId) {
    let newAlbumId = null;
    if (currentAlbumId === 1) {
      newAlbumId = 2;
    }

    if (currentAlbumId === 2) {
      newAlbumId = 1;
    }
    setCurrentSongByAlbum(newAlbumId);
  }

  function handleSongChange(newSongId) {
    setCurrentSongById(newSongId);
    setShowingDropdownOptions(false);
  }

  function handleDropdownClick() {
    setShowingDropdownOptions(!showingDropdownOptions);
  }

  return (
    <div className="musicControllers flex-container">
      <div className="left-group flex-item">
        {currentSong.album_id === 1 ? (
          <p
            onClick={() => handleAlbumChange(currentSong.album_id)}
            className="musicControllers-vol"
          >
            vol. I
          </p>
        ) : (
          <p
            onClick={() => handleAlbumChange(currentSong.album_id)}
            className="musicControllers-vol"
          >
            vol. II
          </p>
        )}

        <DropdownButton
          key={"up"}
          id={`dropdown-button-drop-up-centered`}
          drop={"up"}
          variant="secondary"
          title={currentSong.name}
          show={showingDropdownOptions}
          onClick={() => handleDropdownClick()}
          className="custom-dropdown"
        >
          {showingDropdownOptions &&
            (songsByAlbum[currentSong.album_id]?.length > 0 ? (
              songsByAlbum[currentSong.album_id].map((song) => (
                <Dropdown.Item
                  key={song.id}
                  onClick={() => handleSongChange(song.id)}
                  className="custom-dropdown-item"
                >
                  {song.name}
                </Dropdown.Item>
              ))
            ) : (
              <Dropdown.Item disabled>No songs available</Dropdown.Item>
            ))}
        </DropdownButton>
      </div>

      <div className="center-group flex-item">
        <img
          loading="eager"
          className="control-button"
          onClick={() => handlePrevSong()}
          src="../assets/icons/previous-song.svg"
          alt="Previous Song"
          title="Previous Song"
        />

        {play ? (
          <img
            loading="eager"
            className="control-button"
            onClick={() => handlePlaySong()}
            src="../assets/icons/pause.svg"
            alt="Pause Song"
            title="Pause Song"
          />
        ) : (
          <img
            loading="eager"
            className="control-button"
            onClick={() => handlePlaySong()}
            src="../assets/icons/play.svg"
            alt="Play Song"
            title="Play Song"
          />
        )}

        <img
          loading="eager"
          className="control-button"
          onClick={() => handleNextSong()}
          src="../assets/icons/next-song.svg"
          alt="Next Song"
          title="Next Song"
        />
      </div>

      <div className="right-group flex-item">
        {muted ? (
          <img
            loading="eager"
            className="control-button"
            src="../assets/icons/mute.svg"
            alt="sound off"
            title="sound off"
            onClick={() => handleMute()}
          />
        ) : (
          <img
            loading="eager"
            className="control-button"
            src="../assets/icons/unmute.svg"
            alt="sound on"
            title="sound on"
            onClick={() => handleMute()}
          />
        )}
      </div>

      <audio
        id="audio_tag"
        src={`${process.env.REACT_APP_UPLOAD_FOLDER}${currentSong.audio}`}
        autoPlay
        loop
        muted={muted}
      />
    </div>
  );
}
