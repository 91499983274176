import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "../lib/axiosConfig.js";
import DOMPurify from "dompurify";

import "../styles/credits.css";

export default function Credits({ songId }) {
  const [credits, setCredits] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/songs/${songId}/credits`);
        const creditsData = res.data;
        setCredits(creditsData);
      } catch (err) {
        console.error("Error getting credits: ", err);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <div className="credits-info">
        <img
          loading="lazy"
          className="img-eyes"
          src="../assets/images/olhos.png"
          alt="eyes"
          title="eyes"
        />
        <h2>créditos</h2>

        <div className="credits-roles-authors">
          {credits.map((credit, index) => {
            const { role, authors } = credit;
            const sanitizedMoreInfo = DOMPurify.sanitize(credit.more_info);
            if (role !== "Sem Cargo") {
              return (
                <span key={index}>
                  <span className="role"> {role} </span>
                  {authors.map((author) => author.authors_name).join(", ")}
                </span>
              );
            } else {
              return (
                <div
                  className="more info"
                  dangerouslySetInnerHTML={{ __html: sanitizedMoreInfo }}
                />
              );
            }
          })}
        </div>

        <div className="credits-exit">
          <Link to="/">
            <img
              loading="lazy"
              src="../assets/icons/exit.svg"
              alt="exit"
              title="exit"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
