import { useEffect } from "react";
import React from "react";

import { CurrentState } from "../context/currentState.js";
import { USER_STATES } from "../context/currentState.js";
import "../styles/landingPage.css";

export function LandingPage() {
  const { handleUserStateChange } = React.useContext(CurrentState);

  useEffect(() => {
    const contentElement = document.querySelector(".content");
    const eyesElement = document.querySelector(".eyes");

    // Enhanced function to detect iOS versions, including older ones
    function isIOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13+ detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    }

    if (isIOS()) {
      contentElement.classList.add("hidden");
      eyesElement.classList.add("hidden");
      handleUserStateChange(USER_STATES.SONG_MENU);
    }
  }, []);

  return (
    <>
      <div className="eyes">
        <img
          loading="eager"
          className="img-eyes"
          src="../assets/images/olhos.png"
          alt="eyes"
          title="eyes"
        />
      </div>
      <div className="content">
        <h1>MASSAIÁ</h1>
        <p>espaços da voz, do som e do olhar</p>
        <div className="content">
          <img
            loading="eager"
            src="../assets/images/massaiaintro1.png"
            alt="Massaiá"
            title="Massaiá"
          />
          <img
            loading="eager"
            className="img1"
            src="../assets/images/massaiaintro2.png"
            alt="Massaiá"
            title="Massaiá"
          />
          <img
            loading="eager"
            className="img2"
            src="../assets/images/massaiaintro3.png"
            alt="Massaiá"
            title="Massaiá"
          />
          <img
            loading="eager"
            className="img3"
            src="../assets/images/massaiaintro4.png"
            alt="Massaiá"
            title="Massaiá"
          />
        </div>
      </div>
      <div className="construction-notice">
        <p>em construção</p>
      </div>
    </>
  );
}
