import { Helmet } from "react-helmet-async";

export default function SEO({ title, content, href }) {
    return (
        <Helmet prioritizeSeoTags>
            <title>{title}</title>
            <meta 
                name="description"
                content={content}
            />
            <link 
                rel="canonical" 
                href={href}
            />
        </Helmet>
    )
}