import { useParams } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import Carousel from "react-bootstrap/Carousel";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SEO from "../components/SEO.jsx";
import Header from "../components/Header";
import MusicControllers from "../components/MusicControllers";
import { CacheApi } from "../context/cacheApi.js";
import { CurrentState } from "../context/currentState.js";
import { USER_STATES } from "../context/currentState.js";
import "../styles/gallery.css";

export default function Gallery() {
  const { songId } = useParams();
  const [isFullArtPiece, setIsFullArtPiece] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const { userState, currentSong, setCurrentSongById, setPrevPage } =
    useContext(CurrentState);
  const { artPiecesBySong, initArtPieces } = useContext(CacheApi);

  const [activeArt, setActiveArt] = useState(null); // Store the currently displayed art piece
  const [modalImageSize, setModalImageSize] = useState({
    width: "auto",
    height: "auto",
  });

  useEffect(() => {
    setCurrentSongById(songId);
    initArtPieces();
    setPrevPage(`/galeria/${songId}`);
  }, [songId]);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (artPiecesBySong[currentSong.id]?.length > 0) {
      setActiveArt(artPiecesBySong[currentSong.id][0]); // Set the first art piece as default
    }
  }, [artPiecesBySong, currentSong.id]);

  const toggleArtPiece = () => {
    setIsFullArtPiece(!isFullArtPiece);
  };

  const handleCarouselSelect = (selectedIndex) => {
    const selectedArtPiece = artPiecesBySong[currentSong.id][selectedIndex];
    setActiveArt(selectedArtPiece); // Update active art when the carousel changes
  };

  // Adjust modal dimensions based on image size
  useEffect(() => {
    if (activeArt && isFullArtPiece) {
      const img = new Image();
      img.src = `${process.env.REACT_APP_UPLOAD_FOLDER}/${activeArt.image}`;
      img.onload = () => {
        // Get the image dimensions
        const maxModalWidth = screenWidth * 0.9; // Set max width at 90% of screen width
        const maxModalHeight = window.innerHeight * 0.9; // Set max height at 90% of screen height

        let width = img.naturalWidth;
        let height = img.naturalHeight;

        // Scale down if image is larger than the modal max dimensions
        if (width > maxModalWidth) {
          const scale = maxModalWidth / width;
          width = maxModalWidth;
          height *= scale;
        }
        if (height > maxModalHeight) {
          const scale = maxModalHeight / height;
          height = maxModalHeight;
          width *= scale;
        }

        setModalImageSize({ width, height });
      };
    }
  }, [activeArt, isFullArtPiece, screenWidth]);

  return (
    <>
      <SEO
        title="Galeria | MASSAIÁ"
        content="Veja as várias obras de arte realizadas por diversos autores e inspiradas nas músicas de Helena Caspurro."
        href={window.location.href}
      />
      <div className="gallery-container">
        <div className="header-container">
          <Header />
        </div>
        {userState === USER_STATES.LOADING_PAGE && (
          <div className="carousel-container">
            <div className="spinner-container">
              <Spinner animation="grow" variant="dark" />
            </div>
          </div>
        )}
        {userState !== USER_STATES.LOADING_PAGE && screenWidth >= 769 ? (
          <div className="carousel-container">
            <Carousel
              className="display_art_pieces"
              onSelect={handleCarouselSelect}
            >
              {artPiecesBySong[currentSong.id]?.length > 0 ? (
                artPiecesBySong[currentSong.id].map((artPiece, index) => (
                  <Carousel.Item
                    className="art_piece"
                    key={artPiece.id}
                    onClick={toggleArtPiece} // Show modal when an item is clicked
                  >
                    <img
                      loading="lazy"
                      src={`${process.env.REACT_APP_UPLOAD_FOLDER}/${artPiece.image}`}
                      alt={artPiece.song_name}
                      title={artPiece.song_name}
                    />
                    <Carousel.Caption>
                      <h3>{artPiece.author_name}</h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                ))
              ) : (
                <h3 style={{ margin: "2rem" }}>
                  Esta Música ainda não tem obras disponíveis.
                </h3>
              )}
            </Carousel>
          </div>
        ) : (
          <div className="grid-container">
            {artPiecesBySong[currentSong.id]?.length > 0 ? (
              artPiecesBySong[currentSong.id].map((artPiece) => (
                <div className="artPiece-grid-item" key={artPiece.id}>
                  <img
                    loading="lazy"
                    src={`${process.env.REACT_APP_UPLOAD_FOLDER}/${artPiece.image}`}
                    alt={artPiece.song_name}
                    title={artPiece.song_name}
                    onClick={() => setActiveArt(artPiece)}
                    className={activeArt?.id === artPiece.id ? "active" : ""}
                  />
                  {activeArt?.id === artPiece.id && (
                    <div className="art_info">
                      <h3>{artPiece.author_name}</h3>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="grid-item">
                <div className="art_info">
                  <h3>Esta Música ainda não tem obras disponíveis.</h3>
                </div>
              </div>
            )}
          </div>
        )}
        <MusicControllers />

        {/* Modal for displaying the active art piece in full view */}
        {isFullArtPiece && screenWidth >= 769 && activeArt && (
          <Modal
            show={isFullArtPiece}
            onHide={toggleArtPiece}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal-size"
          >
            <Modal.Body>
              <img
                loading="lazy"
                src={`${process.env.REACT_APP_UPLOAD_FOLDER}/${activeArt.image}`}
                alt={activeArt.song_name}
                title={activeArt.song_name}
                style={{ height: "80vh", width: "auto" }}
              />
            </Modal.Body>
          </Modal>
        )}
      </div>
    </>
  );
}
